import React from 'react'
import "./about.css"
import ME from "../../assets/Me-about.jpg"
import {BiAward} from "react-icons/bi"
import {MdOutlineSchool} from 'react-icons/md'
const about = () => {
    
    return (
        <section id='about'>
            <h5> Get To Know Linshengyi</h5>
            <h2>ABout Me</h2>

            <div className= "container about_container">
                {/* <div className= "about_me">
                    <div className= "about_me-image">
                        <img src={ME} alt="About Image"/>
                    </div>
                </div> */}

                <div className="about_content">
                    <div className="about_cards">
                        <article className="about_card">
                            <MdOutlineSchool className="about_icon"/>
                            <h5>Education</h5>
                            <small>Rice University <br/>M.S. Computer Science<br/></small>
                            <small> August, 2023 - Current<br/></small>
                            <small>Oregon State University <br/>B.S. Computer Science<br/></small>
                            <small> 3.84 GPA | September, 2019 - December, 2022</small>


                        </article>
                        <article className="about_card">
                            <BiAward className="about_icon"/>
                            <h5>Experience</h5>
                            <small>Techincal Tester<br/>January, 2023 - April, 2023<br/></small>
                            <small>Programmming Tutor<br/>January, 2022 - December, 2022<br/></small>
                            <small>Autoparts Agent<br/>April, 2021 - December, 2022<br/></small>
                        </article>
                        <article className="about_card">
                            <BiAward className="about_icon"/>
                            <h5>Skills</h5>
                            <small>AWS · Agile · Communcation · Git · C++ · Python · Java · Kotlin · Techincal Writing · 
                            Project Management · Data Analysis · Machine Learning · Source Code Review 
                            · Chinese </small>
                        </article>
                    </div>
                    <p id="about_paragraph">
                    First of all, thank you for visiting my website! My name is Linshengyi Sun, though I go by William. During my undergraduate years at OSU, I worked hard and graduated within 3 years while maintaining a high GPA and working 2 part-time jobs. My motivation was my passion for technology and ambition to become a provider for my family someday.<br/><br/>

I have over 3 years of experience collaborating in team environments, designing, integrating, developing, and testing programs. I gained substantial experience in technical writing and Agile project management while serving as a team lead using Git for version control and collaboration.<br/><br/>

I am bilingual in English and Chinese, allowing me to effectively communicate with and build relationships between international clients and coworkers.<br/><br/>

And now, a new chapter has started. I will be attending Rice University's MCS program to earn my master's degree, along with absorbing more advanced knowledge, meeting extraordinary people, and exploring the new city of Houston!<br/><br/>

During my personal time, I enjoy reading novels, listening to music (and sometimes singing too), playing video games with friends, working out, and watching Netflix or YouTube with my girlfriend.<br/><br/>


                    </p>
                    {/* <a href="#contact" className="btn btn-primary connect-button"> Let's Connect</a>                         */}
                </div>
            </div>
            
        </section>

    )
}

export default about
